<template>
  <div>
    <v-row>
      <v-col cols="auto" align-self="center">
        <v-btn icon :to="`/nicht-zugeordnet/d/${zuschauer.datum}`" exact>
          <v-icon large>mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
      <v-col>
        <h2 class="white--text text-uppercase">Zuschauerdetails</h2>
      </v-col>
    </v-row>
    <v-row justify="center" class="mt-4" v-if="zuschauer">
      <v-col cols="10" class="px-2 pb-0">
        <v-text-field
          v-model="zuschauer.vorname"
          outlined
          label="Vorname"
          v-on:change="update()"
        ></v-text-field>
      </v-col>
      <v-col cols="10" class="px-2 py-0">
        <v-text-field
          v-model="zuschauer.name"
          outlined
          label="Nachname"
          v-on:change="update()"
        ></v-text-field>
      </v-col>
      <v-col cols="10" class="px-2 py-0">
        <v-text-field
          v-model="zuschauer.adresse"
          outlined
          :readonly="!edit"
          label="Straße + Nr."
          v-on:change="update()"
        ></v-text-field>
      </v-col>
      <v-col cols="4" class="px-2 py-0">
        <v-text-field
          v-model="zuschauer.plz"
          outlined
          label="Postleitzahl"
          v-on:change="update()"
        ></v-text-field>
      </v-col>
      <v-col cols="6" class="px-2 py-0">
        <v-text-field
          v-model="zuschauer.ort"
          outlined
          label="Ort"
          v-on:change="update()"
        ></v-text-field>
      </v-col>
      <v-col cols="10" class="px-2 py-0">
        <v-text-field
          v-model="zuschauer.telefon"
          outlined
          label="Telefon-/Mobilnummer"
          v-on:change="update()"
        ></v-text-field>
      </v-col>
      <v-col cols="10" class="px-2 py-0">
        <v-text-field
          v-model="zuschauer.zutritt"
          outlined
          label="Ankunft"
          v-on:change="update()"
        ></v-text-field>
      </v-col>
      <v-col cols="10" class="px-2 py-0">
        <v-text-field
          v-model="zuschauer.verlassen"
          outlined
          label="Verlassen"
          v-on:change="update()"
        ></v-text-field>
      </v-col>
      <v-col cols="10" class="text-center" v-if="zuschauer.weitere_personen">
        <h3 v-if="zuschauer.weitere_personen.length > 0">
          Weitere Personen aus dem Haushalt:
        </h3>
        <v-chip
          v-for="(p, index) in zuschauer.weitere_personen"
          :key="index"
          class="ma-2"
          color="grey lighten-1"
          text-color="#111"
          large
          close
          close-icon="mdi-close"
          @click:close="delete_person(index)"
        >
          <v-avatar left>
            <v-icon>mdi-account-circle</v-icon>
          </v-avatar>
          {{ p }}
        </v-chip>
      </v-col>
      <v-col cols="10" class="text-center">
        <v-btn
          color="success"
          :outlined="add_person"
          @click="add_person = !add_person"
        >
          <v-icon v-if="!add_person">mdi-plus</v-icon>
          {{ add_person ? 'Abbrechen' : 'Weitere Person aus Haushalt' }}
        </v-btn>
      </v-col>
      <v-expand-transition>
        <v-col cols="10" class="text-center" v-if="add_person">
          <v-text-field
            label="Vor- und Nachname"
            filled
            v-model="new_person"
          ></v-text-field>
          <v-btn
            color="success"
            @click="
              weitere_person()
              new_person = ''
              add_person = false
            "
          >
            <v-icon>mdi-plus</v-icon>
            Hinzufügen
          </v-btn>
        </v-col>
      </v-expand-transition>
    </v-row>
    <v-divider class="my-5"></v-divider>
    <v-row justify="center">
      <v-col cols="10" class="px-2">
        <v-btn
          :color="template.colors.primary"
          block
          large
          outlined
          @click="deleteZuschauer()"
        >
          Zuschauer entfernen
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
//import firebase from '../../../../firebaseConfig'
import { mapGetters } from 'vuex'
import store from '../../../../store'
import router from '../../../../routes/index'

export default {
  name: 'zuschauer-bearbeiten',
  data() {
    return {
      zuschauer: '',
      add_person: false,
      new_person: '',
    }
  },
  mounted() {
    if (this.$route.params.zuschauer) {
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Anwesenheit')
        .doc(this.$route.params.zuschauer)
        .get()
        .then((doc) => {
          this.zuschauer = doc.data()
          this.zuschauer.id = doc.id
        })
        .catch((error) => {
          console.log(error)
        })
    } else {
      router.push('/nicht-zugeordnet')
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      template: 'template',
    }),
  },
  methods: {
    weitere_person() {
      if (this.new_person) {
        if (!this.zuschauer.weitere_personen) {
          this.zuschauer.weitere_personen = []
        }
        this.zuschauer.weitere_personen.push(this.new_person)
        this.update()
      }
    },
    delete_person(index) {
      var i
      var hilf = this.zuschauer.weitere_personen
      this.zuschauer.weitere_personen = []
      for (i = 0; i < hilf.length; i++) {
        if (hilf[i] != hilf[index]) {
          this.zuschauer.weitere_personen.push(hilf[i])
        }
      }
      this.update()
    },
    update() {
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Anwesenheit')
        .doc(this.$route.params.zuschauer)
        .update({
          vorname: this.zuschauer.vorname,
          name: this.zuschauer.name,
          adresse: this.zuschauer.adresse,
          plz: this.zuschauer.plz,
          ort: this.zuschauer.ort,
          telefon: this.zuschauer.telefon,
          zutritt: this.zuschauer.zutritt,
          verlassen: this.zuschauer.verlassen,
          weitere_personen: this.zuschauer.weitere_personen,
        })
        .catch((error) => {
          console.log('Es ist ein Fehler aufgetreten!', error)
        })
    },
    deleteZuschauer() {
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Anwesenheit')
        .doc(this.$route.params.zuschauer)
        .delete()
        .then(() => {
          router.push('/nicht-zugeordnet/d/' + this.zuschauer.datum)
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>
